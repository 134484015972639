import axios from "axios";
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications);

const base_url = process.env.VUE_APP_API_URL;
const lang_api = 'it';


let api = {
    getToken: function () {
        var token = localStorage.getItem('mapspartout_bearer') || '';
        return token;
    },
    request: function (action, method, params, data) {
        return new Promise((resolve, reject) => {

            let options = {
                method: method || "GET",
                url: base_url + action,
                headers: {
                    'authorization': 'Bearer ' + this.getToken(),
                    'accept-language': lang_api,
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            }
            if (params) {
                options["params"] = params;
            }

            if (data) {
                options["data"] = data;
            }

            axios(options)
                .then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        console.log(error.response.data.errors);

                        var textMessage = "";
                        if(error.response.data.errors){
                        for( const [key, value] of Object.entries(error.response.data.errors)  ){

                            if( key !== 'debug'){
                                if( Array.isArray(value) ){
                                    textMessage += value.join("<br>");
                                }else {
                                    textMessage += value;
                                }
                                textMessage += "<br>";
                            }
                        }
                    }


                        if( error.response.data.message  ){
                            textMessage += error.response.data.message;
                        }

                        if( error.response.data.data &&  error.response.data.data.message  ){
                            textMessage +=  error.response.data.data.message;
                        }



                        Vue.notify({
                            type: 'error',
                            text: textMessage,
                            duration: 5000,
                            speed: 500
                        });

                        if (error.response.status === 403) {
                          localStorage.clear();
                          location.replace(process.env.VUE_APP_API_BASEPATH + "/#/login");
                          reject(error);
                        }

                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    reject(error);
                });
        });
    },
}

api.categories = require("./categories").default(api);
api.menu = require("./menu").default(api);
api.user = require("./user").default(api);

export default api;
