function user(api) {

    return {
        async current() {
            let userData = JSON.parse(localStorage.getItem('user'));
            let now = (new Date().getTime());

            if (userData && userData.expire >= now) {
                return userData;
            }

            if (api.getToken() === "") {
                return null;
            }

            try {
                userData = (await api.request('user/me', 'get', null, {})).data;
                userData.expire = now + 20 * 1000;
                localStorage.setItem('user', JSON.stringify(userData));
                var token = api.getToken();
                var expires = "; expires=" + (new Date(userData.expire)).toUTCString();
                document.cookie = 'ert-token' + "=" + (token || "") + expires + "; path=/";
            } catch (error) {
                localStorage.clear();
                userData = null;
            }
            return userData;
        },
        async can(ability) {
            let userData = await this.current();
            return (
                userData.roles.hasOwnProperty("administrator") ||
                userData.abilities.hasOwnProperty("*") ||
                userData.abilities.hasOwnProperty(ability)
            );
        },
        async isAdmin() {
            let userData = await this.current();
            return userData.roles.hasOwnProperty("administrator");
        },
        logout() {
            api.request('logout', 'get').finally(() => {
                localStorage.clear();
                location.replace(process.env.VUE_APP_API_BASEPATH + "/#/login");
            });
        },
        async login(username, password) {

            if (username == undefined && password == undefined) {
                return this.current();
            }

            try {


                let login = await api.request('login', 'post', null, {
                    username: username,
                    password: password,
                });
                localStorage.setItem('mapspartout_bearer', login.data.access_token);
                return login.data;
            } catch (error) {

                return null;
            }





        },
        async index(params) {

            try {
                return await api.request('user', 'get', params || {});

            } catch (error) {
                return [];
            }

        }
        ,

        async save(item) {

        }


    }


}
export default user;
