function menu(api) {

    return {

        index: function (parent_id) {
            return api.request('menu', 'get', { 'main': parent_id });
        },
        save(id, data) {
            if (id == "new" || id == null) {
                return api.request('menu', 'post', null, data);
            }
            return api.request('menu/' + id, 'put', null, data);
        },
        remove(id) {
          if (id == "new" || id == null) {
              return []
          }
          return api.request('menu/' + id, 'delete', null, {});
      },
        update_order: function (list) {
            return api.request('menu/update_order', 'post', null, { 'array': list });
        }

    }


}
export default menu;
